import React from 'react';
import './App.scss';

function App() {
    return (
        <div className="App">
            <div class="container height-full center">
                <a href="https://www.linkedin.com/in/daniele-favaro-0a4711109/">
                    <h1 class="glitch" data-text="Daniele Favaro">Daniele Favaro</h1>
                </a>
                <p class="subtitle">Android Developer. Based in Stockholm.</p>
            </div>
        </div>
    );
}

export default App;
